import React from 'react'
import tw from 'twin.macro'
import { ALink } from '../components/A'
import { H1 } from '../components/Headings'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../helpers'

const browser = typeof window !== 'undefined' && window

const NotFoundPage = ({ data, location }) => {
  const postSchema = formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw
  return (
    browser && (
      <Layout location={location}>
        <Seo title="404: Not found" postSchema={postSchema} />
        <div tw="min-h-50 pt-32 pb-20 flex flex-col items-center justify-center text-center">
          <H1 css={['font-size: 12rem; line-height: 1em;', tw`font-condensed`]}>
            404
          </H1>
          <p tw="block text-2xl text-gray-500 mb-12">Page Not Found</p>
          <p>
            Oops, looks like you found a bug.{' '}
            <span role="img" aria-label="bug-emoji">
              🐞
            </span>{' '}
            Maybe try <ALink to="/">going home?</ALink>
          </p>
        </div>
      </Layout>
    )
  )
}

export const query = graphql`
  {
    wp {
      ...WpSeoSchema
    }
  }
`

export default NotFoundPage
